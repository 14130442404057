import {
    store
} from "../../../../../main"
import {
    LEAD_ANALYZE_CATEGORIES_PERMISSIONS,
    LEAD_PERMISSIONS
} from "./permissions.gh"


export const LEAD_BUTTONS = {
    LIST: {
        name: "Leads",
        description: "Will open Leads List",
        icon: "mdi-file-document-multiple-outline",
        color: "#23842a",
        on: "onLeadsList",
        permission: LEAD_PERMISSIONS.ALLOW_SEE_LEAD_LIST,
        handler: (props) => store.dispatch('LeadStore/openLeadList', props)
    },
    CREATE_NEW: {
        name: "Create",
        description: "Will create new Lead",
        icon: "mdi-plus",
        color: "#23842a",
        on: "onLeadCreate",
        permission: LEAD_PERMISSIONS.ALLOW_CREATE_LEAD,
        handler: (props) => store.dispatch('LeadStore/openLeadCreation', props)
    },
    SAVE: {
        name: "Save",
        description: "Will save new Lead",
        icon: "mdi-check",
        color: "#23842a",
        on: "onLeadSave",
        permission: LEAD_PERMISSIONS.ALLOW_CREATE_LEAD
    },
    SETTINGS_EDITOR_MAIN: {
        name: "Settings",
        description: "Will open Lead Settings",
        icon: "mdi-cog",
        color: "#23842a",
        on: "onLeadSettings",
        permission: LEAD_PERMISSIONS.ALLOW_EDIT_LEAD,
        handler: (props) => store.dispatch('LeadStore/openLeadSettingsEditor', props)
    },
    FILES_EDITOR_MAIN: {
        name: "Attachments",
        description: "Will open Attachments",
        icon: "mdi-file-document-multiple",
        color: "#23842a",
        on: "onLeadFilesEdit",
        permission: LEAD_PERMISSIONS.ALLOW_SEE_LEAD_ATTACHMENTS,
        handler: (props) => store.dispatch('LeadStore/openLeadFilesEditor', props)
    },
    GENERAL_EDITOR_MAIN: {
        name: "Settings",
        description: "Will open General Settings",
        icon: "mdi-cog",
        color: "#23842a",
        on: "onLeadGeneralEdit",
        permission: LEAD_PERMISSIONS.ALLOW_EDIT_LEAD,
        handler: (props) => store.dispatch('LeadStore/openLeadGeneralEditor', props)
    },
    QUESTIONNAIRE_MAIN: {
        name: "Questionnaire",
        description: "Will open Lead Questionnaire. It helps to calculate Impact score and recommend plan",
        icon: "mdi-alpha-q-box",
        color: "#9D1646",
        on: "onLeadQuestionnaire",
        permission: LEAD_PERMISSIONS.ALLOW_EDIT_LEAD,
        handler: (props) => store.dispatch('LeadStore/openLeadQuestionnaire', props)
    },
    PROPOSAL_CREATION_MAIN: {
        name: "Proposal Creation",
        description: "You can generate proposal based on the recommendations",
        icon: "mdi-chart-timeline",
        color: "#005A9A",
        on: "onLeadProposalCreation",
        permission: LEAD_PERMISSIONS.ALLOW_EDIT_LEAD,
        handler: (props) => store.dispatch('DocAssistantStore/openProposalAssistant', props)
    },
    HISTORY_MAIN: {
        name: "History",
        description: "Will open Lead History",
        icon: "mdi-history",
        color: "#23842a",
        on: "onLeadHistory",
        permission: LEAD_PERMISSIONS.ALLOW_SEE_LEAD_HISTORY,
        handler: (props) => store.dispatch('LeadStore/openLeadHistory', props)
    },
    // =================== EDITORS ===================
    GENERAL_EDITOR: {
        name: "Settings",
        description: "Will open General configurations",
        icon: "mdi-cog",
        color: "#23842a",
        on: "onLeadGeneralEdit",
        permission: LEAD_PERMISSIONS.ALLOW_EDIT_LEAD,
        handler: (props) => store.dispatch('LeadStore/openLeadGeneralEditor', props)
    },
    FILES_EDITOR: {
        name: "Edit",
        description: "Will open Attachments",
        icon: "mdi-pencil",
        color: "#23842a",
        on: "onLeadFilesEdit",
        permission: LEAD_PERMISSIONS.ALLOW_EDIT_LEAD,
        handler: (props) => store.dispatch('LeadStore/openLeadFilesEditor', props)
    },
}




export const LEAD_ANALYZE_CATEGORIES_BUTTONS = {
    LIST: {
        name: "Analyze Categories",
        description: "Will open Analyze Categories",
        icon: "mdi-file-document-multiple-outline",
        color: "#23842a",
        on: "onLeadsAnalyzeCategoriesList",
        permission: LEAD_ANALYZE_CATEGORIES_PERMISSIONS.ALLOW_SEE_ANALYZE_CATEGORIES_LIST,
        handler: (props) => store.dispatch('AnalyzeCategoryStore/openAnalyzeCategoryList', props)
    },
    CREATE_NEW: {
        name: "Create",
        description: "Will create new Analyze Category",
        icon: "mdi-plus",
        color: "#23842a",
        on: "onLeadAnalyzeCategoryCreate",
        permission: LEAD_ANALYZE_CATEGORIES_PERMISSIONS.ALLOW_CREATE_ANALYZE_CATEGORIES,
        handler: (props) => store.dispatch('AnalyzeCategoryStore/openAnalyzeCategoryCreation', props)
    },
    SAVE: {
        name: "Save",
        description: "Will save new Analyze Category",
        icon: "mdi-check",
        color: "#23842a",
        on: "onLeadAnalyzeCategorySave",
        permission: LEAD_ANALYZE_CATEGORIES_PERMISSIONS.ALLOW_CREATE_ANALYZE_CATEGORIES
    }
}