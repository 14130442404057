<template>
  <AView
    :value="value"
    :hasFilters="!!(filter.search || filter.type)"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
  >
    <template v-slot:content="{}">
      <v-row>
        <v-col cols="12">
          <FormSection
            right
            :label="'Leads List'"
            :icon="value.view.display.icon"
            :actions="actions"
            :editable="!!actions.length"
            :payload="{
              from: value,
            }"
          ></FormSection>
        </v-col>
      </v-row>

      <v-row class="scroll-row d-flex flex-grow-1">
        <v-col cols="12" class="pa-0 ma-0 d-flex flex-column flex-grow-1">
          <v-virtual-scroll
            class="pa-0 ma-0"
            :bench="5"
            :items="leads"
            height="630"
            item-height="450"
            @scroll="onScroll"
          >
            <template v-slot:default="{ item }">
              <LeadItemVue
                :key="item.id"
                :active="item.id === activeLeadId"
                @onOpen="onSelect"
                @onAssign="onAssign"
                @onBusinessDomainClick="onBusinessDomainClick"
                @click="onSelect"
                @mouseover="onHover"
                @mouseleave="() => {}"
                @onMarkAsInvalid="onMarkAsInvalid"
                :value="item"
                :style="{
                  height: '440px',
                }"
                class="ma-2"
              ></LeadItemVue>
            </template>
          </v-virtual-scroll>
        </v-col>
      </v-row>
      <Pagination
        :value="pagination"
        :show="showMore"
        :total="leads.length"
        @onLoadMore="
          $store.dispatch('LeadStore/GetLeadsList', {
            next: true,
          })
        "
      ></Pagination>
    </template>

    <template v-slot:actions="{}">
      <LeadFilters v-model="filter"> </LeadFilters>
    </template>
  </AView>
</template>
      
      
      
    <script>
import { mapState } from "vuex";
import LeadItemVue from "../../../../../components/wad/organisms/leads/LeadItem.vue";
import FormSection from "../../../../../components/wad/atoms/common/FormSection.vue";
import AView from "../../../../../components/wad/organisms/layout/A-View.vue";
import LeadFilters from "../../../../../components/wad/molecules/leads/LeadFilters.vue";
import Pagination from "../../../../../components/wad/atoms/common/Pagination.vue";
import { LEAD_BUTTONS } from "../constants/actions.gh";
import { PermissionsHelper } from "../../../../../components/helpers/permissions.helper";

export default {
  name: "LeadList",
  props: {
    value: {
      default: () => {},
    },
  },
  components: {
    AView,
    FormSection,
    LeadItemVue,
    LeadFilters,
    Pagination,
  },
  computed: {
    ...mapState("LeadStore", ["activeLeadId", "leads", "pagination"]),
    actions: function () {
      return PermissionsHelper.getActions(
        LEAD_BUTTONS.CREATE_NEW,
        this.value.permissions
      );
    },
  },
  created() {
    this.$store.dispatch("LeadStore/GetLeadsList", {
      reset: true,
    });
  },
  beforeDestroy() {
    clearInterval(this.updateInterval);
  },
  data() {
    return {
      filter: {},
      showMore: false,
      updateInterval: undefined,
    };
  },
  methods: {
    onHover(value) {
      this.$store.commit("LeadStore/setActiveId", value.id);
    },
    onSelect(lead) {
      this.$store.dispatch("LeadStore/SetActiveLead", {
        from: this.value,
        lead,
      });
    },
    onAssign(lead) {
      this.$store.dispatch("LeadStore/assign", {
        lead,
      });
    },
    onMarkAsInvalid(props) {
      //props =>badwords + lead
      this.$store.dispatch("LeadStore/markAsInvalid", props);
    },
    onBusinessDomainClick(businessDomain) {
      this.$store.dispatch("BusinessDomainStore/SetActiveBusinessDomain", {
        from: this.value,
        businessDomain,
      });
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        console.log("END");
        this.showMore = true;
      } else {
        this.showMore = false;
      }
    },
  },
  watch: {
    filter: {
      handler(newVal) {

        console.log('NEV VAL: ', newVal)

        this.$store.dispatch("LeadStore/GetLeadsList", {
          filter: newVal,
          reset: true,
        });
      },
      deep: true,
    },
  },
};
</script>
      
      
      
  <style scoped lang="scss" >
.scroll-row {
  height: calc(100% - 110px);
}
</style>