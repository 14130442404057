<template>
  <div class="service-filters">
    <h2 class="white--text">FILTERS</h2>
    <HelpFormInputVue
      dark
      v-model="pSearch"
      :timeout="300"
      label="Search"
      :helpText="'Provide any text to search across services'"
      :tooltipAttrs="{
        left: true,
      }"
    ></HelpFormInputVue>

    <h3 class="white--text">Analyze Categories</h3>

    <v-chip-group
      active-class="primary--text"
      column
      v-model="pCategories"
      multiple
    >
      <v-chip
        label
        v-for="category in categories.items"
        :key="category.id"
        :value="`${category.id}`"
      >
        {{ category.name }}
      </v-chip>
    </v-chip-group>

    <h3 class="white--text">Domains</h3>

    <v-chip-group
      active-class="primary--text"
      column
      v-model="pDomains"
      multiple
    >
      <v-chip
        label
        v-for="domain in domains.items"
        :key="domain.id"
        :value="`${domain.id}`"
      >
        {{ domain.name }}
      </v-chip>
    </v-chip-group>

    <h3 class="white--text">Quick Filters</h3>

    <v-chip-group active-class="primary--text" column v-model="pQuickFilters">
      <v-chip label v-for="tag in tags" :key="tag" :value="tag">
        {{ tag }}
      </v-chip>
    </v-chip-group>
  </div>
</template>


<script>
import { KnowledgeBaseAPIInstance, LeadsAPIInstance } from "../../../api";
import HelpFormInputVue from "../../atoms/common/inputs/HelpFormInput.vue";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      filters: {},
      pSearch: this.value.search ? this.value.search : undefined,
      pType: this.value.type ? this.value.type : undefined,
      pQuickFilters: this.value.quickFilters
        ? this.value.quickFilters
        : undefined,

      tags: ["CONTACT US", "UPWORK"],
      pDomains: undefined,
      pCategories: undefined,
      domains: {
        pagination: {
          pageSize: 50,
        },
        items: [],
      },
      categories: {
        pagination: {
          pageSize: 50,
        },
        items: [],
      },
    };
  },
  components: {
    HelpFormInputVue,
  },
  created() {
    this.getBusinessDomains();
    this.getAnalyzeCategories();
  },
  methods: {
    async getBusinessDomains() {
      this.domains = await KnowledgeBaseAPIInstance.BusinessDomains.list(
        this.domains.pagination
      );
    },
    async getAnalyzeCategories() {
      this.categories = await LeadsAPIInstance.AnalyzeCategory.list(
        this.categories.pagination
      );
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.filters = newVal;
      },
      deep: true,
    },
    filters: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },

    search(newVal) {
      this.pSearch = newVal;
    },

    pSearch(newVal) {
      this.$emit("input", {
        search: newVal,
        quickFilters: this.pQuickFilters,
        domainIds: this.pDomains,
        categoriesIds: this.pCategories,
      });
    },

    pQuickFilters: {
      handler(newVal) {
        this.$emit("input", {
          search: this.pSearch,
          quickFilters: newVal,
          domainIds: this.pDomains,
          categoriesIds: this.pCategories,
        });
      },
      deep: true,
    },

    pDomains: {
      handler(newVal) {
        this.$emit("input", {
          search: this.pSearch,
          quickFilters: this.pQuickFilters,
          domainIds: newVal,
          categoriesIds: this.pCategories,
        });
      },
      deep: true,
    },
    pCategories: {
      handler(newVal) {
        this.$emit("input", {
          search: this.pSearch,
          quickFilters: this.pQuickFilters,
          domainIds: this.pDomains,
          categoriesIds: newVal,
        });
      },
      deep: true,
    },

    pType(newVal) {
      this.$emit("input", {
        type: newVal,
      });
    },
  },
};
</script>


<style lang="scss" scoped>
.service-filters {
  // margin-right: 30px;
  padding: 40px;
  width: 100%;
}
</style>