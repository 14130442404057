<template>
  <v-card
    :loading="loading"
    class="lead-item bg-grey-gradient"
    v-bind="$attrs"
    @click="() => $emit('click', value)"
    :ripple="tab === 0 ? true : false"
    @mouseover="() => $emit('mouseover', value)"
    @mouseleave="() => $emit('mouseleave', value)"
  >
    <div :class="{ active }" class="item-active-indicator"></div>

    <div class="lead-type-img control"></div>

    <div
      class="lead-status"
      :class="{
        success: value.upwork_lead_id,
      }"
    >
      <span v-if="value.type === 'UPWORK'">UPWORK</span>
      <span v-else-if="value.type === 'CONTACT_US'">CONTACT US</span>
      <span v-else>{{ value.type }}</span>
    </div>

    <v-card-title class="lead-title">
      <v-list class="transparent pa-0">
        <v-list-item>
          <v-list-item-avatar class="pa-0">
            <v-icon
              v-if="value.probability === 'HIGHEST'"
              class="mb-1"
              color="success"
              >mdi-chevron-double-up</v-icon
            >
            <v-icon
              v-else-if="value.probability === 'HIGH'"
              class="mb-1"
              color="lime lighten-1"
              >mdi-chevron-up</v-icon
            >
            <v-icon
              v-else-if="value.probability === 'MEDIUM'"
              class="mb-1"
              color="deep-orange darken-2"
              >mdi-chevron-down</v-icon
            >
            <v-icon v-else class="mb-1" color="error"
              >mdi-chevron-double-down</v-icon
            >
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="mb-2">
              <a
                class="title-link"
                v-if="value.upworkLead && value.upworkLead.link"
                :href="value.upworkLead.link"
                target="blank"
              >
                {{ value.name }}
              </a>
              <a class="title-link" v-else href="">
                {{ value.name }}
              </a>
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-chip
                small
                class="mr-2"
                label
                outlined
                color="primary"
                v-if="value.analyzeCategory"
                >{{ value.analyzeCategory.name }}</v-chip
              >
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-spacer></v-spacer>
    </v-card-title>
    <v-divider class="mt-2 mr-4 ml-4"></v-divider>
    <!-- <v-row>
        <v-col>
          <v-icon>mdi-thermometer </v-icon>
        </v-col>
        <v-col>
          <v-icon>mdi-currency-usd </v-icon>
        </v-col>
      </v-row> -->
    <v-tabs-items class="transparent" dark v-model="tab">
      <v-tab-item :key="0">
        <div>
          <v-row class="pl-2 pr-2" v-if="value.domains && value.domains.length">
            <v-chip
              class="ma-2"
              v-for="item in value.domains.slice(0, 3)"
              :key="item.domain.id"
              @click.stop.prevent="
                () => $emit('onBusinessDomainClick', item.domain)
              "
              label
            >
              {{ item.domain.name }}
            </v-chip>
          </v-row>

          <v-card-text class="mt-0 pt-0">
            <v-row class="justify-space-between">
              <v-col cols="12">
                <v-row align="center" class="mx-0"> </v-row>

                <div class="my-4 text-subtitle-1"></div>

                <div class="">
                  <!-- <h3>{{ value.short_description }}</h3> -->
                  <h4 class="brief">INITIAL INFO:</h4>
                  <p class="simple-text lead-description">
                    {{ cutFormatter(value.initial_info, 150) }}
                  </p>
                </div>
              </v-col>
              <!-- <v-col cols="4"> </v-col> -->
            </v-row>

            <v-tooltip
              bottom
              v-if="value.upworkLead && value.upworkLead.estBudget"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip label class="ma-2" v-bind="attrs" v-on="on">
                  <v-icon class="mr-2">mdi-currency-usd</v-icon>
                  {{ formatPrice(value.upworkLead.estBudget * 100) }}
                </v-chip>
              </template>
              <span>Fixed Price Budget</span>
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="
                value.upworkLead &&
                (value.upworkLead.minHourRate || value.upworkLead.maxHourRate)
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip label class="ma-2" v-bind="attrs" v-on="on">
                  <v-icon class="mr-2">mdi-cash-clock</v-icon>
                  {{ formatPrice(value.upworkLead.minHourRate * 100) }}
                  -
                  {{ formatPrice(value.upworkLead.maxHourRate * 100) }}
                </v-chip>
              </template>
              <span>Hourly Rate</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip label class="ma-2" v-bind="attrs" v-on="on">
                  <v-icon class="mr-2">mdi-thermometer</v-icon>

                  {{
                    (value.valid || value.valid === 0
                      ? value.valid
                      : value.upworkLead &&
                        (value.upworkLead.valid || value.upworkLead.valid === 0)
                      ? value.upworkLead.valid
                      : 0
                    ).toFixed(2)
                  }}/{{
                    (value.invalid || value.invalid === 0
                      ? value.invalid
                      : value.upworkLead &&
                        (value.upworkLead.invalid ||
                          value.upworkLead.invalid === 0)
                      ? value.upworkLead.invalid
                      : 0
                    ).toFixed(2)
                  }}
                </v-chip>
              </template>
              <span>Lead Validity: (Valid/Invalid)</span>
            </v-tooltip>
          </v-card-text>
        </div>
      </v-tab-item>

      <!-- FOUND KEWYORDS FOR FEEDBACK LOOP -->
      <v-tab-item :key="1">
        <div v-if="!showAnimation" @click.stop.prevent="() => {}">
          <v-row>
            <v-col>
              <HelpFormInputVue
                dark
                class="pa-2 pb-0"
                :help-text="'You can specify a custom keywords if you wish'"
                :label="'Your keyword'"
                v-model="newKeyword"
                @onEnter="addKeyword"
              ></HelpFormInputVue>
            </v-col>
          </v-row>

          <v-row v-if="keywords && keywords.length" class="pl-2 pr-2 badwords">
            <v-chip-group
              v-model="badwords"
              column
              multiple
              active-class="primary--text"
            >
              <v-chip
                class="ma-2"
                v-for="keyword in keywords"
                :key="keyword"
                :value="keyword"
                @click.stop.prevent="() => $emit('onLeadRemove', keyword)"
                label
                v-show="keyword.match(new RegExp(newKeyword, 'ig'))"
              >
                {{ keyword }}
              </v-chip>
            </v-chip-group>
          </v-row>
        </div>
        <div v-else class="success-checkmark">
          <div class="check-icon">
            <span class="icon-line line-tip"></span>
            <span class="icon-line line-long"></span>
            <div class="icon-circle"></div>
            <div class="icon-fix"></div>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>
    <div class="card-actions-bottom">
      <v-divider class="mx-4"></v-divider>
      <v-card-actions>
        <v-chip class="mr-2" label outlined>{{
          formatDate(value.created_at)
        }}</v-chip>
        <v-chip
          label
          outlined
          v-if="value.upworkLead && value.upworkLead.posted"
          >{{ value.upworkLead.posted }}</v-chip
        >
        <v-spacer></v-spacer>

        <v-btn
          color="secondary"
          text
          @click.stop.prevent="() => $emit('onAssign', value)"
          v-if="tab === 0"
        >
          Assign to Me
        </v-btn>
        <v-btn
          color="secondary"
          text
          @click.stop.prevent="tab = 0"
          v-if="tab === 1 && value.type === 'UPWORK'"
        >
          Back
        </v-btn>
        <v-btn
          color="primary"
          text
          v-if="tab === 0"
          @click.stop.prevent="
            value.type === 'UPWORK' ? (tab = 1) : saveFeedback()
          "
        >
          Mark as Invalid
        </v-btn>
        <v-btn
          color="primary"
          text
          v-if="tab === 1 && value.type === 'UPWORK'"
          @click.stop.prevent="saveFeedback"
        >
          Save Feedback
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { FormatterHelper } from "../../../helpers/formatter.helper";
import keywordExtractor from "keyword-extractor";
import HelpFormInputVue from "../../atoms/common/inputs/HelpFormInput.vue";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Boolean,
      default: false,
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      durationFormatter: FormatterHelper.timeDuration,
      formatDate: FormatterHelper.formatDate,
      cutFormatter: FormatterHelper.cutString,
      formatPrice: FormatterHelper.price,

      loading: false,
      show: false,
      tab: 0,
      badwords: [],
      newKeyword: "",
      showAnimation: false,
      keywords: keywordExtractor.extract(
        this.value.name + " " + this.value.initial_info,
        {
          language: "english",
          remove_digits: true,
          return_changed_case: true,
          remove_duplicates: true,
        }
      ),
    };
  },
  components: {
    HelpFormInputVue,
  },

  methods: {
    onOpen() {
      this.$emit("onOpen", this.value);
    },
    onEdit() {
      this.$emit("onEdit", this.value);
    },
    addKeyword() {
      this.keywords.splice(0, 0, this.newKeyword);
      this.badwords.push(this.newKeyword);
      this.newKeyword = "";
    },
    saveFeedback() {
      this.showAnimation = true;
      this.tab = 1;

      console.log("saveFeedback");

      this.$emit("onMarkAsInvalid", {
        badwords: this.badwords,
        lead: this.value,
      });
    },
  },
};
</script>


<style lang="scss" scoped>
.lead-item {
  z-index: 2;
  overflow: hidden;

  .title-link {
    text-decoration: none;
    color: white;
    transition: color 0.12s ease-in-out;
    font-size: 1.2rem;

    &:hover {
      color: brown;
    }
  }
  .lead-type-img {
    position: absolute;
    height: 100%;
    z-index: -1;
    left: 0;
    top: 0;
    width: 30%;
    -webkit-mask-image: -webkit-gradient(
      linear,
      left top,
      left right,
      from(rgba(0, 0, 0, 1)),
      to(rgba(0, 0, 0, 0))
    );
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .lead-title {
    text-transform: uppercase;
    display: inline-block;
    padding-right: 60px;
  }

  .brief {
    color: #cfd8dc !important;
  }

  .lead-status {
    transform: rotate(45deg);
    background-color: red;
    color: white;
    position: absolute;
    right: -40px;
    width: 150px;
    top: 0;
    text-transform: uppercase;
    /* overflow: hidden; */
    /* margin: 0 auto; */
    text-align: center;
    top: 22px;
  }

  .badwords {
    max-height: 100px;
    overflow: auto;
  }

  .success-checkmark {
    width: 80px;
    height: 115px;
    margin: 0 auto;
    margin-top: 64px;

    .check-icon {
      width: 80px;
      height: 80px;
      position: relative;
      border-radius: 50%;
      box-sizing: content-box;
      border: 4px solid #4daf50;

      &::before {
        top: 3px;
        left: -2px;
        width: 30px;
        transform-origin: 100% 50%;
        border-radius: 100px 0 0 100px;
      }

      &::after {
        top: 0;
        left: 30px;
        width: 60px;
        transform-origin: 0 50%;
        border-radius: 0 100px 100px 0;
        animation: rotate-circle 4.25s ease-in;
      }

      &::before,
      &::after {
        content: "";
        height: 100px;
        position: absolute;
        background: transparent;
        transform: rotate(-45deg);
      }

      .icon-line {
        height: 5px;
        background-color: #4daf50;
        display: block;
        border-radius: 2px;
        position: absolute;
        z-index: 10;

        &.line-tip {
          top: 46px;
          left: 14px;
          width: 25px;
          transform: rotate(45deg);
          animation: icon-line-tip 0.75s;
        }

        &.line-long {
          top: 38px;
          right: 8px;
          width: 47px;
          transform: rotate(-45deg);
          animation: icon-line-long 0.75s;
        }
      }

      .icon-circle {
        top: -4px;
        left: -4px;
        z-index: 10;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        position: absolute;
        box-sizing: content-box;
        border: 4px solid rgba(77, 175, 80, 0.3);
      }

      .icon-fix {
        top: 8px;
        width: 5px;
        left: 26px;
        z-index: 1;
        height: 85px;
        position: absolute;
        transform: rotate(-45deg);
        background-color: transparent;
      }
    }
  }

  @keyframes rotate-circle {
    0% {
      transform: rotate(-45deg);
    }
    5% {
      transform: rotate(-45deg);
    }
    12% {
      transform: rotate(-405deg);
    }
    100% {
      transform: rotate(-405deg);
    }
  }

  @keyframes icon-line-tip {
    0% {
      width: 0;
      left: 1px;
      top: 19px;
    }
    54% {
      width: 0;
      left: 1px;
      top: 19px;
    }
    70% {
      width: 50px;
      left: -8px;
      top: 37px;
    }
    84% {
      width: 17px;
      left: 21px;
      top: 48px;
    }
    100% {
      width: 25px;
      left: 14px;
      top: 45px;
    }
  }

  @keyframes icon-line-long {
    0% {
      width: 0;
      right: 46px;
      top: 54px;
    }
    65% {
      width: 0;
      right: 46px;
      top: 54px;
    }
    84% {
      width: 55px;
      right: 0px;
      top: 35px;
    }
    100% {
      width: 47px;
      right: 8px;
      top: 38px;
    }
  }
}
</style>